import React from "react"
import {Redirect, Route, Switch} from "react-router";
import Home from "../pages/Main/Home/Home";
import AboutApplication from "../pages/Main/AboutApplication/AboutApplication";
import Feedback from "../pages/Main/Feedback/Feedback";
import JobApplication from "../pages/Main/JobApplication/JobApplication";
import Locations from "../pages/Main/Locations/Locations";
import AboutUs from "../pages/Main/AboutUs/aboutUs";
import Franchise from "../pages/Franchise/Franchise";
import FranchiseOpeningForm from "../pages/Franchise/FranchiseOpeningForm/franchiseOpeningForm";
import ShopStopper from "../pages/Shop/Shop";
import PresentationFranchise from "../pages/Franchise/PresentationFranchise/PresentationFranchise";

const routes = (
   <React.Fragment>
      <Switch>
         {/* =================== Home ======================*/}

          <Route exact path="/home" render={props => (<Home {...props}/>)}/>

          {/* =================== About mobile app ======================*/}
          <Route
            path="/about_mobile_app"
            component={AboutApplication}
            exact
         />

          {/* =================== Feedback ======================*/}
          <Route
            path="/feedback"
            component={Feedback}
            exact
         />

          {/* =================== Job application ======================*/}
          <Route
              path="/job_application"
              component={JobApplication}
              exact
          />

          {/* =================== Locations ======================*/}
          <Route
              path="/locations"
              component={Locations}
              exact
          />

          {/* =================== About us ==================== */}
          <Route
              path="/about_us"
              component={AboutUs}
              exact
          />

          {/*=====================Franchise=======================*/}
          <Route
              path="/franchise"
              component={Franchise}
              exact
          />

          {/*===================== Franchise form =======================*/}
          <Route
          path="/formFranchise"
          component={FranchiseOpeningForm}
          exact
          />

          {/* ========================================================*/}

          <Redirect exact from="/" to="/home"/>
      </Switch>
   </React.Fragment>
)

export default routes