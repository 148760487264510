import React from "react";

const ru_pages = {
    home: {
        about_mobile_app: "О мобильном приложении",
        feedback: "Оставить отзыв",
        locations: "Наши локации",
        job_application: "Подать заявку на работу",
        about_us: "О нас",
    },
    leave_review: {
        salepoint: "Торговая точка",
        areas: {
            "1": "Качество обслуживания",
            "2": "Качество напитка",
            "3": "Общий отзыв про точку",
            "4": "Общий отзыв про сеть",
            '5': "Мобильное приложение",
        },
        cities: {
            uman: "Умань",
            kropivniskiy: "Кропивницкий",
            bilaTserkva: "Белая Церковь",
        },
        value: {
            uman: "uman",
            kropivniskiy: "kropivniskiy",
            bilaTserkva: "bilaTserkva",
        },
        salepoints: {
            uman: [
                "Улица Независимости, 8",
                "Улица Гайдамацкая, 1",
                "Улица Небесной сотни, 27",
                "Улица Независимости, 72",
                "Улица Большая Фонтанная, 31",
                "Улица Европейская, 23",
                "Улица Тыщика, 35",
                "Улица Тыщика, 8",
                "Улица Максима Зализняка, 1",
            ],
            kropivniskiy: [
                "Улица Космонавта Попова, 9Б",
                "Улица Героев Украины, 6/13",
                "Улица Набережная, 13",
                "Улица Полтавская, 81а",
                "Улица Вокзальная, 20/1",
                "Улица Архитектора Паученко, 38",
                "Улица Большая Перспективная, 5/6",
                "Улица Академика Тамма, 1б",
                "Улица Преображенская, 84",
                "Площадь Б. Хмельницкого, 10",
                'Парк "Победы"',
            ],
            bilaTserkva: [
                "Улица Героев небесной сотни, 2а",
                "Улица Турчанинова, 2"
            ],
        },
        raccoon_phrase: "Помоги нам стать лучше!",
    },
    our_location: {
        city: {
            location_uman: "Наши кофейни в Умани",
            location_kropivniskiy: "Наши кофейни в Кропивницком",
            location_bilaTserkva: "Наши кофейни в Белой Церкви"
        },
        place: {
            uman: {
                1: "Улица Независимости, 8",
                2: "Улица Гайдамацкая, 1",
                3: "Улица Небесной сотни, 27",
                4: "Улица Независимости, 72",
                5: "Улица Большая Фонтанная, 31",
                6: "Улица Европейская, 23",
                7: "Улица Тыщика, 35",
                8: "Улица Тыщика, 8",
                9: "Улица Максима Зализняка, 1",
            },
            kropivniskiy: {
                1: "Улица Космонавта Попова, 9Б",
                2: "Улица Героев Украины, 6/13",
                3: "Улица Набережная, 13",
                4: "Улица Полтавская, 81а",
                5: "Улица Вокзальная, 20/1",
                6: "Улица Архитектора Паученко, 38",
                7: "Улица Большая Перспективная, 5/6",
                8: "Улица Академика Тамма, 1б",
                9: "Улица Преображенская, 84",
                10: "Площадь Б. Хмельницкого, 10",
                11: 'Парк "Победы"',
            },
            bilaTserkva: {
                1: "Улица Героев небесной сотни, 2а",
                2: "Улица Турчанинова, 2",
            },
        },
    },
    resume: {
        title: "Резюме",
        placeholder: {
            date_birth: "15",
            month_birth: "Август",
            year_birth: "2021",
            city: "Умань",
            employment: "Учусь",
            term: "3 месяца",
        },
        description_photo: "Добавьте удачное фото, желательно лицо в анфас. " +
            "Не добавляйте фото в полный рост, с друзьями и животными.",
        name: {
            title: "ФИО",
            placeholder: "Например: Иванов Иван Иванович",
        },
        phone_number: "Номер телефона",
        date_of_birth: {
            title: "Дата рождения",
            date: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15", "16","17",
                "18","19","20", "21","22","23","24","25", "26","27","28","29","30","31"],
            month: [ "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
            year:
                ['2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010',
                '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997',
                '1996', '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986', '1985', '1984',
                '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976', '1975', '1974', '1973', '1972', '1971',
                '1970', '1969', '1968', '1967', '1966', '1965', '1964', '1963', '1962', '1961', '1960', '1959', '1958',
                '1957', '1956', '1955', '1954', '1953', '1952', '1951', '1950', '1949', '1948', '1947', '1946', '1945',
                '1944', '1943', '1942', '1941', '1940', '1939', '1938', '1937', '1936', '1935', '1934', '1933', '1932',
                '1931', '1930', '1929', '1928', '1927', '1926', '1925', '1924', '1923', '1922', '1921', '1920']
        },
        sex: {
            sex: "Пол",
            male: "Мужской",
            female: "Женский",
        },
        location: {
            title: "Город",
            cities: {
                uman: "Умань",
                kropivniskiy: "Кропивницкий",
                bilaTserkva: "Белая Церковь",
            },
        },
        activity: {
            title: "В данный момент, я",
            employment: {
                work: "Работаю",
                study: "Учусь",
                unemployed: "Временно безработный",
            },
        },
        term_job: {
            title: "Ищу работу на",
            term: {
                "1": "3 месяца",
                "2": "6 месяцев",
                "3": "9 месяцев",
                "4": "1 год",
                "5": "больше года",
            },
        },
        experience:{
            title: "Опыт работы бариста",
            placeholder: "Например: Да, 3 месяца",
        },
        desired_salary:{
            title: "Хочу получать",
            placeholder: "Например: 5000 грн. в месяц",
        },
        your_preferences: "Почему мы должны взять именно Вас?",
    },
    about_application: {
        description:{
            slide2: {
                title:{
                    "1": "Преврати ежедневный прием кофе в увлекательную игру!",
                    "2": "Получайте бонусы:",
                    "3": "Используйте бонусы:",
                },
                parts:{
                    partOne:  "Удобное мобильное приложение для тех, кто любит кофе и скидки." +
                        "Собирайте бонусы в приложении и оплачивайте ими покупки в сети кофеен Coffee Mobile.",
                    partTwo: {
                        "1": "- Покупайте в сети кофеен – получайте кэшбек",
                        "2": "- Выполняйте постоянные и недельные задания",
                        "3": "- Приглашайте друзей в мобильном приложении"},
                    partThree: {
                        "1": "- Приобретите эксклюзивную продукцию в магазине мобильного приложения",
                        "2": "- Подарите бонусы родным, близким и друзьям",
                        "3": "- Покупайте напитки и товары за бонусы на любой точке в сети кофеен"},
                },
            },
            slide3: {
                title: "Coffee mobile – это не только вкусно, но еще интересно и выгодно! ",
                mainContent: {
                    "1": "1. За каждую покупку вы получаете кэшбек и накапливаете собственные бонусы",
                    "2": "2. Покупайте за бонусы в нашей сети кофеен",
                    "3": "3. Выполняйте еженедельные задания и получайте бонусы",
                    "4": "4. С помощью геолокации можете найти ближайшую торговую точку",
                    "5": "5. Вы можете легко найти нас в социальных сетях",
                },
            },
            slide4: {
                title: {
                    "1": "Наслаждайтесь кофем – и получайте награды.",
                    "2": "Еженедельная новинка!",
                },
                parts: {
                    partOne: "Есть разные интересные награды, которые вы можете получать просто покупая кофе. \n" +
                        "Это не только награда и титул, но и бонусы, с помощью которых вы можете совершать покупки в нашей сети.\n" +
                        "Станьте лидером в кофейной гонке!",
                    partTwo: "Мы не позволим вам скучать и меняем недельные миссии",
                    partThree: {
                        "1": "• просматривайте отличия, которые уже получили или хотите получить",
                        "2": "• за каждое заработанное отличие – вы получаете бонусы!"},
                },
            },
            slide5: {
                title: "С приложением Coffeе mobile вы только в плюсе!",
                parts: {
                    partOne: "С каждой покупки вы получаете кэшбек – это одновременно и скидка, " +
                        "и начисление бонусов на вашу карту coffee мobile.",
                    partTwo: "1 бонус – 1 виртуальная гривна. то есть – это условные единицы, которыми" +
                        "можно рассчитываться в сети coffee mobile." +
                        "Бонусы не имеют наличного выражения и не могут быть получены в денежном эквиваленте",
                    partThree: "Также у вас есть возможность просматривать чеки, за любой период времени с каждой покупки.",
                },
            },
            slide6: {
                title: "Анализируй и удивляй себя!",
                parts: {
                    partOne: "Наше приложение фиксирует статистику напитков которые вы пьете за разный " +
                        "период времени (год, месяц, неделя), бонусы, которые вы тратите или приобретаете и прочее.",
                    partTwo: "Вы сможете узнать:",
                    partThree: {
                        "1": "• какой напиток вы выбираете чаще всего",
                        "2": "• движение бонусов на карте",
                        "3": "• и увидеть начисление бонусов по категориям."},
                },
            },
            slide7: {
                title: "Делай добро на расстоянии!",
                parts: {
                    partOne: "Вы можете дарить бонусы другим пользователям приложения." +
                        "Вам нужно знать только номер телефона." +
                        "Подарить бонусы - это как угостить близкого человека кофе не находясь рядом.",
                    partTwo: "А еще добавляя комплимент в комментарии, вы подарите не только кофе," +
                        "а и хорошее настроение на целый день :)",
                },
            },
            slide8: {
                title: "Оставляйте отзывы – это делает нас ближе!",
                parts: {
                    partOne: "Знаем, что вы любите, когда к вам прислушаются, а мы любим читать приятные слова." +
                        "Поэтому оставляйте свои комментарии по следующим категориям:",
                    partTwo: {
                        "1": "• Качество обслуживания",
                        "2": "• Качество напитка",
                        "3": "• Общий отзыв о точке",
                        "4": "• Общий отзыв о сети",
                        "5": "• Мобильное приложение",
                    },
                    partThree: "Конечно, мы будем рады читать ваши отзывы.",
                },
            },
            slide9: {
                title: "Будь свободным!",
                parts: {
                    partOne: "Вы можете сделать приложение не только более удобным" +
                        "в собственном использовании но и более привлекательным, изменив:",
                    partTwo: {
                        "1": "• Тему",
                        "2": "• Цвет ",
                        "3": "• Язык"
                    },
                },
            },
        },
    },
    about_our_company: {
      first_description: "Coffee mobile – это бренд, который начинался с нескольких кофемашин," +
          " а теперь уже объединяет десятки автокофеен в трех городах." +
          " Мы выбрали автокофейню для того, чтобы быть доступными для наших посетителей почти в каждой части города." +
          " Наши ценности – это использование высококачественной продукции и высокий уровень работников.",

        second_description: "Наше видение – постоянное развитие и все для нашего клиента. " +
            "Мы быстро реагируем на отзывы, комментарии и пожелания посетителей. " +
            "Наши работники любят свою работу и подарят клиентам приятные эмоции и качественное обслуживание.",

        three_description: {
          part_one: "У нас есть мобильное приложение, которое превращает ежедневный прием кофе в увлекательную игру. " +
              "Мы вам предлагаем новую дисконтную систему, кэшбек с каждой покупки, удобный интерфейс, много бонусов и наград.",

          part_two: "Каждый день мы стараемся максимально информировать наших посетителей через наши социальные сети." +
              " Объявления, новости, розыгрыши – это все для наших клиентов.",
        },
    },
    home_page: {
        slogan: "Больше, чем просто замечательный кофе!",
        download_application: "Загружай приложение",
    },
    franchise:{
        franchise_form:{
            title: "Заполните форму",
            name:  "ФИО",
            number_tel: "Номер телефона",
            email: "Электронная почта",
            number_of_birth: {
                label: "Дата рождения",
                placeholder_month: "Август",
            },
            sex: {
                label: "Пол",
                male: "Мужской",
                female: "Женский",
            },
            city_residence: "Город проживания",
            city_franchise: "В каком городе вы бы хотели открыть Coffee Mobile?",
            other_franchises: "Есть ли у вас, дополнительно, другие франшизы?",
            experience_area_services: "Есть ли у вас опыт работы в сфере услуг?",
            franchise_type: {
                label: "Какой тип франшизы рассматриваете?",
                placeholder: "Выберите вариант",
                variant: ["Подключение существующей кофейни к франшизе Сoffee Mobile", "Открытие новой кофейни с франшизой Сoffee Mobile"]
            },
            learned_information: "Откуда вы узнали о Coffee Mobile?",
            desired_budget: "На какой бюджет инвестиции рассчитываете?",
        },
        franchise_banner: {
            title: "Открой Coffee Mobile в своем городе",
            button: "Преобрести франшизу",
        },
    franchise_formats: {
        title: "Форматы Франшизы",
        formats: {
            car: "Автокофейня",
            cup: "Стакан",
            maf: "МАФ",
        },
        format_description: {
            car: "Объекты с установленным кофейным оборудованием в автомобиле (To Go без посадки). Легкая в открытии и передвижении по местности.",
            cup: "Кофейня в виде кофейного стакана. Объект островных типов MINI (To Go без посадки).",
            maf: "Объекты островных типов MINI (To Go без посадки). Если позволяет местность устанавливаются" +
                " посадочные места с самообслуживанием. Данный формат предполагает наличие оформления витрины.",
        },
    },
    terms_of_cooperation: {
        title: "Общие условия сотрудничества",
        items_list: {
            "1": "1. Использование торговой марки «Coffee Mobile»",
            "2": "2. Первый взнос и ежемесячный платеж 3% от дохода ",
            "3": "3. Заказ товара и всех комплектующих через наших официальных представителей ",
            "4": "4. Подписание договора поставок продукции ",
            "5": "5. Соблюдение норм и правил работы сети",
        }
    },
    franchise_advantage: {
        title: "Преимущества франшизы",
        advantage_first: "Готовая бонусная программа в удобном мобильном приложении",
        advantage_second: "Обеспечение товарами, рекламной продукцией",
        advantage_third: "Система автоматизации кофейни с ПРРО - бесплатно",
        advantage_fourth: "Поддержка на разных этапах развития",
        advantage_fifth: "Помощь при выборе локации и необходимого оборудования",
        advantage_sixth: {
            description: <>Доступность франшизы: <br/>
             • разные форматы <br/>
             • невысокие инвестиции <br/>
             • простые технологии </>
        },
    },
        sequence_of_cooperation: {
            title: "Последовательность сотрудничества",
            inscription: {
                first_inscription: "1. Изучение объекта",
                second_inscription: "2. Бизнес-план",
                third_inscription: "3. Подписание договора",
                fourth_inscription: "4. Ремонт и оснащение помещения",
                fifth_inscription: "5. Обучение персонала и передача технологий работы",
                sixth_inscription: "6. Открытие нового кофейни",
            },
        },
        callback_franchise: {
            title: "Больше информации",
            first_icon_description: "Обратная связь",
            second_icon_description: "Заявка на покупку франшизы",
            third_icon_description: "Просмотреть презентацию",
        },
    },
    shop: "Магазин сейчас в разработке!",
    popup: {
        temporary_popup: {
            check_field_empty: "Поля подсвеченые красным – обязательны к заполнению.",
            good_result: "Запрос отправлен. С вами скоро свяжутся",
            failed_result: "Запрос не отправлен. Попробуйте отправить еще раз",
            Invalid_email: "Введите корректную электронную почту",
        },
        resume:{
            successfully_sent: "Резюме отправлено",
            error: "Ошибка. Попробуйте отправить еще раз",
        },
        feedback: {
            successfully: "Благодарим за отзыв! Вы помагаєте нам стать лучше!",
            error: "Отзыв не отправлен. Попробуйте отправить еще раз.",
        },
        franchise_form: {
            successfully:  "Заявка отправлена",
            error:  "Заявка не отправлена. Попробуйте отправить еще раз.",
        },
        franchise_callback: {
            title: "Хотите чтобы мы Вам позвонили?",
            first_label: "Телефонный номер",
            second_label: "Имя",
            button: "Заказать звонок",
            waiting_window: "Ваша заявка отправлена. С вами скоро свяжутся. Заказать вызов повторно можно будет через пять минут.",
        },
    },
}

export default ru_pages