import React from "react"
import TermsOfCooperation from "../../../pages/Franchise/TermsOfCooperation/TermsOfCooperation";
import FranchiseAdvantage from "../../../pages/Franchise/FranchiseAdvantage/FranchiseAdvantage";

const uk_pages = {
    home: {
        about_mobile_app: "Про мобільний застосунок",
        feedback: "Залишити відгук",
        locations: "Наші локації",
        job_application: "Подати заявку на роботу",
        about_us: "Про нас",
    },
    leave_review: {
        salepoint: "Торгова точка",
        areas: {
            "1": "Якість обслуговування",
            "2": "Якість напою",
            "3": "Загальний відгук про точку",
            "4": "Загальний відгук про мережу",
            '5': "Мобільний застосунок",
        },
        cities: {
            uman: "Умань",
            kropivniskiy: "Кропивницький",
            bilaTserkva: "Біла Церква",
        },
        value: {
            uman: "uman",
            kropivniskiy: "kropivniskiy",
            bilaTserkva: "bilaTserkva",
        },
        salepoints: {
            uman: [
                "Вулиця Незалежності, 8",
                "Вулиця Гайдамацька, 1",
                "Вулиця Небесної сотні, 27",
                "Вулиця Незалежності, 72",
                "Вулиця Велика Фонтанна, 31",
                "Вулиця Європейська, 23",
                "Вулиця Тищика, 35",
                "Вулиця Тищика, 8",
                "Вулиця Максима Залізняка, 1"
            ],
            kropivniskiy: [
                "Вулиця Космонавта Попова, 9Б",
                "Вулиця Героїв України, 6/13",
                "Вулиця Набережна, 13",
                "Вулиця Полтавська, 81а",
                "Вулиця Вокзальна, 20/1",
                "Вулиця Архітектора Паученка, 38",
                "Вулиця Велика Перспективна, 5/6",
                "Вулиця Академіка Тамма, 1б",
                "Вулиця Преображенська, 84",
                "Площа Б. Хмельницького, 10",
                'Парк "Перемоги"',
            ],
            bilaTserkva: [
                "Вулиця Героїв небесної сотні, 2а",
                "Вулиця Турчанінова, 2",
            ],
        },
        raccoon_phrase: "Допоможи нам стати краще!",
    },
    our_location: {
        city: {
            location_uman: "Наші кав'ярні в Умані",
            location_kropivniskiy: "Наші кав’ярні в Кропивницькому",
            location_bilaTserkva: "Наші кав’ярні в Білій Церкві"
        },
        place: {
            uman: {
                1: "Вулиця Незалежності, 8",
                2: "Вулиця Гайдамацька, 1",
                3: "Вулиця Небесної сотні, 27",
                4: "Вулиця Незалежності, 72",
                5: "Вулиця Велика Фонтанна, 31",
                6: "Вулиця Європейська, 23",
                7: "Вулиця Тищика, 35",
                8: "Вулиця Тищика, 8",
                9: "Вулиця Максима Залізняка, 1"
            },
            kropivniskiy: {
                1: "Вулиця Космонавта Попова, 9Б",
                2: "Вулиця Героїв України, 6/13",
                3: "Вулиця Набережна, 13",
                4: "Вулиця Полтавська, 81а",
                5: "Вулиця Вокзальна, 20/1",
                6: "Вулиця Архітектора Паученка, 38",
                7: "Вулиця Велика Перспективна, 5/6",
                8: "Вулиця Академіка Тамма, 1б",
                9: "Вулиця Преображенська, 84",
                10: "Площа Б. Хмельницького, 10",
                11: 'Парк "Перемоги"',
            },
            bilaTserkva: {
                1: "Вулиця Героїв небесної сотні, 2а",
                2: "Вулиця Турчанінова, 2",
            },
        }
    },
    resume: {
        title: "Резюме",
        placeholder: {
            date_birth: "15",
            month_birth: "Серпень",
            year_birth: "2021",
            city: "Умань",
            employment: "Навчаюсь",
            term: "3 місяці",
        },
        description_photo: "Додайте вдалу світлину, бажано обличчя в анфас. " +
            "Не додавайте світлину у повний зріст, з друзями і тваринами.",
        name: {
            title: "ПІБ",
            placeholder: "Наприклад: Іванов Іван Іванович",
        },
        phone_number: "Номер телефону",
        date_of_birth: {
            title: "Дата народження",
            date: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17",
                "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
            month: ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень",
                "Вересень", "Жовтень", "Листопад", "Грудень",],
            year: ['2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010',
                '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997',
                '1996', '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986', '1985', '1984',
                '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976', '1975', '1974', '1973', '1972', '1971',
                '1970', '1969', '1968', '1967', '1966', '1965', '1964', '1963', '1962', '1961', '1960', '1959', '1958',
                '1957', '1956', '1955', '1954', '1953', '1952', '1951', '1950', '1949', '1948', '1947', '1946', '1945',
                '1944', '1943', '1942', '1941', '1940', '1939', '1938', '1937', '1936', '1935', '1934', '1933', '1932',
                '1931', '1930', '1929', '1928', '1927', '1926', '1925', '1924', '1923', '1922', '1921', '1920']
        },
        sex: {
            sex: "Стать",
            male: "Чоловіча",
            female: "Жіноча",
        },
        location: {
            title: "Місто",
            cities: {
                uman: "Умань",
                kropivniskiy: "Кропивницький",
                bilaTserkva: "Біла Церква",
            },
        },
        activity: {
            title: "На данний момент, я",
            employment: {
                work: "Працюю",
                study: "Навчаюсь",
                unemployed: "Тимчасово безробітній",
            },
        },
        term_job: {
            title: "Шукаю роботу на",
            term: {
                "1": "3 місяці",
                "2": "6 місяців",
                "3": "9 місяців",
                "4": "1 рік",
                "5": "більше року",
            },
        },
        experience: {
            title: "Досвід роботи бариста",
            placeholder: "Наприклад: Так, 3 місяці",
        },
        desired_salary: {
            title: "Хочу отримувати",
            placeholder: "Наприклад: 5000 грн. в місяць",
        },
        your_preferences: "Чому ми маємо взяти саме Вас?",
    },
    about_application: {
        description: {
            slide2: {
                title: {
                    "1": "Перетвори щоденний прийом кави у захоплюючу гру!",
                    "2": "Отримуйте бонуси:",
                    "3": "Використовуйте бонуси:",
                },
                parts: {
                    partOne: "Зручний мобільний додаток для тих хто любить каву та знижки." +
                        "Збирайте бонуси в додатку та оплачуйте ними покупки в мережі кав’ярень Coffee Mobile.",
                    partTwo: {
                        "1": "- Купуйте в мережі кав’ярень – отримуйте кешбек ",
                        "2": "- Виконуйте постійні та тижневі завдання ",
                        "3": "- Запрошуйте друзів у мобільному додатку"
                    },
                    partThree: {
                        "1": "- Придбайте ексклюзивну продукцію в магазині мобільного додатку",
                        "2": "- Подаруйте бонуси рідним, близьким та друзям",
                        "3": "- Купуйте напої та товари за бонуси на будь-якій точці в мережі кав’ярень",
                    },
                },
            },
            slide3: {
                title: "Coffee mobile – це не лише смачно, а ще цікаво та вигідно! ",
                mainContent: {
                    "1": "1. За кожну покупку ви отримуєте кешбек та накопичуєте власні бонуси",
                    "2": "2. Купуйте за бонуси в нашій мережі кав’ярень",
                    "3": "3. Виконуйте щотижневі завдання – та отримуйте бонуси ",
                    "4": "4. За допомогою геолокації можете знайти найближчу торгову точку ",
                    "5": "5. Ви можете легко знайти нас в соціальних мережах",
                },
            },
            slide4: {
                title: {
                    "1": "Смакуйте каву – та отримуйте нагороди. ",
                    "2": "Щотижня новинка! ",
                },
                parts: {
                    partOne: "Є різні цікаві нагороди, які ви можете отримувати просто куштуючи каву. " +
                        "Це не лише нагорода та титул, а й бонуси, за допомогою яких ви зможете звершувати покупки в нашій мережі." +
                        "Станьте лідером у кавових перегонах!",
                    partTwo: "Ми не дозволимо вам нудьгувати та змінюємо тижневі місії",
                    partThree: {
                        "1": "• переглядайте відзнаки які вже отримали або хочете отримати",
                        "2": "• за кожну зароблену відзнаку – ви отримуєте бонуси!"
                    },
                },
            },
            slide5: {
                title: "З додатком Coffeе mobile ви лише в плюсі!",
                parts: {
                    partOne: "З кожної купівлі ви отримуєте кешбек – це водночас і знижка, " +
                        "і нарахування бонусів на вашу картку coffee мobile. ",
                    partTwo: "1 бонус – 1 віртуальна гривня. тобто – це умовні одиниці, якими" +
                        " можна розраховуватись в мережі coffee mobile." +
                        "Бонуси не мають готівкового вираження і не можуть бути отримані в грошовому еквіваленті",
                    partThree: "Також, у вас є можливість переглядати чеки, за будь-який період часу з кожної покупки. ",
                },
            },
            slide6: {
                title: "Аналізуй та дивуй себе!",
                parts: {
                    partOne: "Наш застосунок фіксує статистику напоїв які ви куштуєте за різний " +
                        "період часу (рік,місяць, тиждень), бонуси які ви витрачаєте або  здобуваєте та інше. ",
                    partTwo: "Ви зможете дізнатись:",
                    partThree: {
                        "1": "• який напій ви обираєте найчастіше ",
                        "2": "• рух бонусів на карті ",
                        "3": "• та побачити нарахування бонусів по категоріях."
                    },
                },
            },
            slide7: {
                title: "Роби добро на відстані!",
                parts: {
                    partOne: "Ви маєте можливість дарувати бонуси іншим користувачам застосунку. " +
                        "Вам потрібно знати лише номер телефону. " +
                        "Подарувати бонуси - це як пригостити близьку людину кавою не знаходячись поруч. ",
                    partTwo: "А ще додаючи комплімент в коментарі, ви подаруєте не лише каву, " +
                        "а й хороший настрій на цілий день :)",
                },
            },
            slide8: {
                title: "Залишайте відгуки - це робить нас ближчими!",
                parts: {
                    partOne: "Знаємо, що ви любите, коли до вас дослухаються, а ми любимо читати приємні слова. " +
                        "Тому залишайте свої коментарі по таким категоріям:",
                    partTwo: {
                        "1": "• Якість обслуговування",
                        "2": "• Якість напою",
                        "3": "• Загальний відгук про точку",
                        "4": "• Загальний відгук про мережу",
                        "5": "• Мобільний додаток ",
                    },
                    partThree: "Звичайно, ми будемо раді читати ваші відгуки.",
                },
            },
            slide9: {
                title: "Будь вільним!",
                parts: {
                    partOne: "Ви маєте можливість зробити застосунок не лише зручнішим " +
                        "у власному використанні але й привабливішим, змінивши:",
                    partTwo: {
                        "1": "• Тему",
                        "2": "• Колір ",
                        "3": "• Мову"
                    },
                },
            },
        },
    },
    about_our_company: {
        first_description: "Coffee mobile – це бренд, який розпочинався з декількох кавомашин," +
            " а наразі вже об’єднує десятки автокав’ярень в трьох містах. " +
            "Ми обрали автокав’ярню для того, щоб бути доступними для наших відвідувачів майже у кожній частині міста." +
            " Наші цінності - це використання високоякісної продукції та високий рівень роботи працівників. ",

        second_description: "Наше бачення – постійний розвиток, та все для нашого клієнта. " +
            "Ми швидко реагуємо на відгуки, коментарі та побажання наших відвідувачів." +
            " Наші працівники люблять свою роботу та подарують клієнтам приємні емоції та якісне обслуговування.",

        three_description: {
            part_one: "У нас є мобільний застосунок який перетворює щоденний прийом кави у захоплюючу гру. " +
                "Ми вам пропонуємо нову дисконтну систему, кешбек з кожної покупки, зручний інтерфейс, багато бонусів та нагород. ",

            part_two: "Щодня ми намагаємося максимально інформувати наших відвідувачів через наші соціальні мережі." +
                " Оголошення, новини, розіграші – це все для нашого клієнта. ",
        },
    },
    home_page: {
        slogan: "Більше, ніж просто чудова кава!",
        download_application: "Завантажуй застосунок",
    },
    franchise: {
        franchise_form: {
            title: "Заповніть форму",
            name: "ПІБ",
            number_tel: "Номер телефону",
            email: "Електронна пошта",
            number_of_birth: {
                label: "Дата народження",
                placeholder_month: "Серпень",
            },
            sex: {
                label: "Стать",
                male: "Чоловіча",
                female: "Жіноча",
            },
            city_residence: "Місто проживання",
            city_franchise: "В якому місті, ви б хотіли відкрити Coffee Mobile?",
            other_franchises: "Маєте додатково інші франшизи?",
            experience_area_services: "Чи маєте досвід роботи у сфері послуг?",
            franchise_type: {
                label: "Який тип франшизи розглядаєте?",
                placeholder: "Оберіть варіант",
                variant: ["Підключення існуючої кав’ярні до франшизи Сoffee Mobile", "Відкриття нової кав’ярні з франшизою Сoffee Mobile"],
            },
            learned_information: "Звідки дізнались про Coffee Mobile?",
            desired_budget: "На який бюджет інвестиції розраховуєте?",
        },
        franchise_banner: {
          title: "Відкрий Coffee Mobile у своєму місті",
          button: "Придбати франшизу",
        },
    franchise_formats: {
        title: "Формати Франшизи",
        formats: {
          car: "Автокав'ярня",
          cup: "Стакан",
          maf: "МАФ",
        },
        format_description: {
          car: "Об’єкти з встановленим кавовим обладнанням в автомобілі (To Go без посадки). Легка в відкритті та пересуванні по місцевості.",
          cup: "Кав’ярня у вигляді кавового стакана. Об’єкт острівних типів MINI (To Go без посадки).",
          maf: "Об’єкти острівних типів MINI (To Go без посадки). Якщо дозволяє місцевість встановлюються" +
              " посадкові місця з самообслуговуванням. Даний формат припускає наявність оформлення вітрини.",
        },
    },
    terms_of_cooperation: {
        title: "Загальні умови співпраці",
        items_list: {
            "1": "1. Використання торговельної марки «Coffee Mobile»",
            "2": "2. Перший внесок та щомісячний платіж 3% від доходу ",
            "3": "3. Замовлення товару та всіх комплектуючих через наших офіційних представників ",
            "4": "4. Підписання договору постачання продукції ",
            "5": "5. Дотримання норм та правил роботи мережі",
        }
    },
    franchise_advantage: {
        title: "Переваги франшизи",
        advantage_first: "Готова бонусна програма в зручному мобільному застосунку",
        advantage_second: "Запезпечення товарами, рекламною продукцією",
        advantage_third: "Система автоматизації кав’ярні з ПРРО - безкоштовно",
        advantage_fourth: "Підтримка на різних етапах розвитку",
        advantage_fifth: "Допомога при виборі локації та необхідного обладнання",
        advantage_sixth: {
            description: <>Доступність франшизи: <br/>
             • різні формати <br/>
             • невисокі інвестиції  <br/>
             • прості технології</>
        },
    },
        sequence_of_cooperation: {
            title: "Послідовність співпраці",
            inscription: {
                first_inscription: "1. Дослідження об'єкту",
                second_inscription: "2. Бізнес-план",
                third_inscription: "3. Підписання договору",
                fourth_inscription: "4. Ремонт та оснащення приміщення",
                fifth_inscription: "5. Навчання персоналу та передача технологій роботи",
                sixth_inscription: "6. Відкриття нової кав'ярні",
            },
        },
        callback_franchise: {
            title: "Більше інформації",
            first_icon_description: "Зворотній зв'язок",
            second_icon_description: "Заявка на придбання франшизи",
            third_icon_description: "Переглянути презентацію",
        },
    },
    shop: "Магазин зараз в розробці!",
    popup: {
        temporary_popup: {
            check_field_empty: "Поля підсвічені червоним - обов'язкові до заповнення.",
            good_result: "Запит надіслано. З вами незабаром зв'яжуться",
            failed_result: "Запит не надіслано. Спробуйте надіслати ще раз",
            Invalid_email: "Введіть коректу електронну пошту",
        },
        resume: {
            successfully_sent: "Резюме надіслано",
            error: "Помилка. Спробуйте надіслати ще раз",
        },
        feedback: {
            successfully: "Дякуємо за відгук! Ви допомагаєте нам стати краще!",
            error: "Відгук не надіслано. Спробуйте надіслати ще раз.",
        },
        franchise_form: {
            successfully: "Заявку надіслано",
            error: "Заявку не надіслано. Спробуйте надіслати ще раз.",
        },
        franchise_callback: {
          title: "Хочете щоб ми Вам зателефонували?",
          first_label: "Номер телефону",
          second_label: "Ім'я",
          button: "Замовити дзвінок",
          waiting_window: "Вашу заявку надіслано. З вами невдовзі зв'яжуться. Замовити дзвінок повторно можна буде через п'ять хвилин.",
        },
    },
}
export default uk_pages