import "./FeedbackFranchise.scss"
import iconGirl from "../../../assets/icons/Group 69.svg"
import iconLetter from "../../../assets/icons/Group 95.svg"
import PresentationIcon from "../../../assets/icons/Group 491.svg";
import {Link} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import PopupFeedback from "../../../components/Popup/PopupFeedback/PopupFeedback";
import getText from "../../../appText/getText";
import {StoreContext} from "../../../App";


function CallbackFranchise() {
    const [callbackPopup, setCallbackPopup] = useState(false);
    const [isSmall, setIsSmall] = useState(false);

    useEffect(() => {
        const onResize = (event) => {
            setIsSmall(event.target.innerWidth < 576)
        }
        window.addEventListener("resize", onResize)

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])

    function stateSwitcher() {
        setCallbackPopup(true)
    }

    return (
        <div className="feedback-page">
            <h1 className="title-callback-page">{getText("franchise.callback_franchise.title")}</h1>
            <div className="feedback-main-content">
                <div className="feedback-button">
                    <img src={iconGirl} className="feedback-button-icon" onClick={stateSwitcher}/>
                    <p className="feedback-button-description-one"
                       onClick={stateSwitcher}>{getText("franchise.callback_franchise.first_icon_description")}</p>
                </div>
                <div className="feedback-button">
                    <Link to="/formFranchise"><img src={iconLetter} className="feedback-button-icon"/></Link>
                    <Link className="button" to="/formFranchise"><p className="feedback-button-description-two">
                        {getText("franchise.callback_franchise.second_icon_description")}</p></Link>
                </div>
                <div className="feedback-button">
                    <a href={!isSmall ? 'https://www.canva.com/design/DAFhMuz1eoc/o2-BVbuh10SzDBEBhXBedg/view?utm_content=DAFhMuz1eoc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink'
                    : 'https://www.canva.com/design/DAFhA1p_hXU/view'}
                       target="_blank"
                    >
                        <img src={PresentationIcon} className="feedback-button-icon"/>
                    </a>
                    <a href={!isSmall ? 'https://www.canva.com/design/DAFhMuz1eoc/o2-BVbuh10SzDBEBhXBedg/view?utm_content=DAFhMuz1eoc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink'
                        : 'https://www.canva.com/design/DAFhA1p_hXU/view'}
                       className="link"
                       target="_blank"
                    >
                        <p className="feedback-button-description-two">{getText("franchise.callback_franchise.third_icon_description")}</p>
                    </a>
                </div>
            </div>
            {callbackPopup && <PopupFeedback setCallbackPopup={setCallbackPopup}/>}
        </div>
    )
}


export default CallbackFranchise;